import promo1 from "../assets/jpg/promo1.jpg";
import promo2 from "../assets/jpg/promo2.jpg";
import promo3 from "../assets/jpg/promo3.jpg";

export default function Promotions() {
  const requests = [
    {
      _id: 2,
      title: "Christmas Eve Happy Hour",
      type: "CLEANING",
      description: "Drink | on 25th December 2022 | 5-6pm",
      status: {
        _id: 1,
        name: "In Progress",
      },
      discount: "Unlimited Drinks",
      img: promo1,
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
    {
      _id: 3,
      title: "10% off for HNB Credit Cards",
      type: "CLEANING",
      description: "Seasonal Offers 2022 | Valid till 31st December 2022",
      img: promo2,
      status: {
        _id: 3,
        name: "Assigned",
      },
      discount: "10% Off",
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
    {
      _id: 4,
      title: "New Year's Eve Dinner Biriyani",
      type: "CLEANING",
      description: "Food & Drink | Valid till 31st December 2022",
      status: {
        _id: 2,
        name: "Done",
      },
      discount: "15% Off",
      img: promo3,
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
  ];

  const COLOR_MAP = {
    1: "red",
    2: "green",
    3: "orange",
    4: "yellow",
    5: "yellow",
  };

  let color = "green";

  return (
    <div>
      <div className="container -mt-5 mx-auto">
        <div className="text-2xl mb-5 font-medium text-center">
          Ongoing Promotions
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {requests.map((e) => {
            return (
              <div
                key={e._id}
                className="card m-2 cursor-pointer border border-gray-400 rounded-lg hover:shadow-md hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200"
              >
                <div className="m-3  grid grid-rows-3 grid-flow-col gap-4">
                  <div class="row-span-3">
                    <img
                      alt="promo image"
                      src={e.img}
                      className="max-h-40 object-cover"
                    />
                  </div>
                  <div class="col-span-2">
                    <h2 className="text-lg mb-2">
                      {e.title}
                      <span
                        className={`text-sm text-black-800 bg-green-200 inline rounded-full px-2 align-top float-right animate-pulse`}
                      ></span>
                    </h2>
                  </div>
                  <div class="row-span-2 col-span-1">
                    <p className="-mt-5 text-sm text-gray-700 hover:text-gray-900 transition-all duration-200">
                      {e.description}
                    </p>
                    <p className="text-lg text-gray-700 hover:text-gray-900">
                      {e.discount}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
