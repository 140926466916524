import imgIrd from "../assets/jpg/ird.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Products() {
  const tabsImported = [
    {
      name: "Breakfast",
      id: "000",
      selected: true,
      products: [
        {
          name: "Goat's curd flat breads",
          description: "Goat's curd flat breads",
          price: 20,
          id: 1,
          image:
            "https://cdn.newsfirst.lk/english-uploads/2022/03/4af85269-d2b4b4de-bread_850x460_acf_cropped.jpg",
        },
        {
          name: "Burger",
          description: "Goat's curd flat breads",
          price: 40,
          id: 2,
          image:
            "https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg",
        },
        {
          id: 3,
          name: "Submarine-Vegetable",
          description: "Submarine-Vegetable",
          price: 24,
          image:
            "https://dinemoreonline.com/wp-content/uploads/2021/09/Submarine-Vegetable.jpg",
        },
      ],
      page: "/welcome",
    },
    {
      name: "Lunch",
      id: "001",
      products: [
        {
          name: "Goat's curd flat breads",
          description: "product 1 description",
          price: 45,
          id: 4,
          image:
            "https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg",
        },
        {
          id: 5,
          name: "Submarine-Vegetable",
          description: "Submarine-Vegetable",
          price: 32,
          image:
            "https://dinemoreonline.com/wp-content/uploads/2021/09/Submarine-Vegetable.jpg",
        },
        {
          name: "Burger-Chicken",
          description: "Goat's curd flat breads",
          price: 13,
          id: 6,
          image:
            "https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg",
        },
      ],
      page: "/category/cleaning",
    },
  ];
  const [tabs, setTabs] = useState(tabsImported);
  const [selectedTab, setselectedTab] = useState(tabsImported[0]);

  const changeTab = (id) => {
    setTabs(
      tabs.map((tab) => {
        return {
          ...tab,
          selected: id === tab.id,
        };
      })
    );
    setselectedTab(tabs.filter((tab) => id === tab.id)[0]);
  };
  return (
    <ul>
      <div className="grid">
        <div className="lg:max-w-lg md:max-w-md sm:max-w-sm sm:w-5/6 justify-self-center">
          <img
            alt={"kic"}
            src={imgIrd}
            className="max-h-40 w-full object-cover rounded shadow shadow-gray-3  00"
          />
          <div className="text-3xl font-medium text-center mt-3">
            Welcome to In Room Dining
          </div>
          <div className="text-xl font-medium mt-2">
            The future of Guest Services
          </div>
          <div className="flex mb-2 mt-3">
            {tabs.map((tab) => {
              return (
                <div
                  className="mr-2 text-xl font-medium py-1 px-4 rounded"
                  style={{
                    color: tab.selected ? "white" : "black",
                    background: tab.selected ? "black" : "white",
                  }}
                  onClick={() => {
                    changeTab(tab.id);
                  }}
                >
                  {tab.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full grid">
        <div className="lg:max-w-lg sm:min-w-sm md:max-w-md sm:w-5/6 justify-self-center">
          {selectedTab.products.map((product) => {
            return (
              <Link to={`/product/${product.id}`} className="w-full">
                <div className="w-full mt-1 mb-2 pb-1 border-b-2 border-gray-300">
                  <div className="flex justify-between">
                    <div>
                      <div className="text-xl font-medium">{product.name}</div>
                      <div>{product.description}</div>
                      <div className="text-xl font-medium text-gray-500">
                        {"USD " + product.price}
                      </div>
                    </div>
                    <img
                      alt={"kic"}
                      src={product.image}
                      className="max-h-20 max-w-20 object-cover rounded shadow shadow-gray-300"
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </ul>
  );
}
