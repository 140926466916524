import { useState } from "react";
import FilledButton from "../components/Button";

export default function OneProduct() {
  const productImported = {
    name: "Goat's curd flat breads",
    description: "product 1 description",
    price: 122,
    id: new Date().toISOString(),
    image:
      "https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg",
  };
  const [product, setproduct] = useState(productImported);
  const [count, setCount] = useState(1);

  const addToCart = () => {
    const cartItems = localStorage.getItem("cart");
    if (cartItems) {
      localStorage.setItem(
        "cart",
        JSON.stringify([...JSON.parse(cartItems), { ...product, count }])
      );
      window.alert("Item is added to the card");
    } else {
      localStorage.setItem("cart", JSON.stringify([{ ...product, count }]));
      window.alert("Item is added to the card");
    }
  };

  return (
    <div className="grid">
      <div className="lg:max-w-lg md:max-w-mg sm:max-w-sm sm:w-5/6 justify-self-center">
        <img
          alt={"kic"}
          src={product.image}
          className="max-h-40 w-full object-cover rounded"
        />
        <div className="text-3xl font-medium text-center">{product.name}</div>
        <div className="text-xl font-medium text-center">
          {"LKR " + product.price}
        </div>
        <div className="flex justify-center my-10">
          <FilledButton
            color="grey"
            fontColor="white"
            title="-"
            onClick={() => {
              if (count > 0) {
                setCount(count - 1);
              }
            }}
          />
          <div className="text-xl font-medium text-center p-2">{count}</div>
          <FilledButton
            color="grey"
            fontColor="white"
            title="+"
            onClick={() => {
              setCount(count + 1);
            }}
          />
        </div>
        <div className="font-medium text-center">
          {"Total Cost: LKR " + product.price * count}
        </div>
        <div className="flex justify-center mt-2">
          <FilledButton
            color="green"
            fontColor="white"
            title="Add to cart"
            onClick={() => {
              addToCart();
            }}
          />
        </div>
      </div>
    </div>
  );
}
