export default function FilledButton({ color, title, fontColor, onClick }) {
  return (
    <div
      className="rounded bg-green-900 center p-2"
      style={{ backgroundColor: color, color: fontColor }}
      onClick={onClick}
    >
      {title}
    </div>
  );
}
