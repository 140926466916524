import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Complaints from "./pages/Complaints";
import CreateRequest from "./pages/CreateRequest";
import { useState } from "react";
import WebCamImage from "./components/WebCamImage";
import Requests from "./pages/Requests";
import Welcome from "./pages/Welcome";
import Restaurent from "./pages/Restaurant";
import Products from "./pages/Products";
import OneProduct from "./pages/OneProduct";
import Feedback from "./pages/Feedback";
import Cart from "./pages/Cart";
import Promotions from "./pages/Promotions";
import BottomNavigator from "./components/BottomNavigator";
import Reservation from "./pages/Reservation";
import FnB from "./pages/FnB";
import Bill from "./pages/Bill";

function App() {
  const [requestImage, setRequestImage] = useState(null);
  const [requestMessage, setRequestMessage] = useState(null);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const setRequestImageCallback = (img) => {
    setRequestImage(img);
  };
  const setRequestMessageCallback = (message) => {
    setRequestMessage(message);
  };
  const setSelectedRestaurantCallback = (restaurant) => {
    alert("ss");
    setSelectedRestaurant(restaurant);
  };
  return (
    <>
      <Router>
        <Header />
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/complaints" element={<Complaints />}></Route>
                <Route path="/my-requests" element={<Requests />}></Route>
                <Route path="/promotions" element={<Promotions />}></Route>
                <Route path="/welcome" element={<Welcome />}></Route>
                <Route path="/feedback" element={<Feedback />}></Route>
                <Route path="/bill" element={<Bill />}></Route>
                <Route
                  path="/restaurents"
                  element={
                    <Restaurent
                      setSelectedRestaurantCallback={
                        setSelectedRestaurantCallback
                      }
                    />
                  }
                ></Route>
                <Route path="/cart" element={<Cart />}></Route>
                <Route
                  path="/reservations/:id"
                  element={
                    <Reservation selectedRestaurant={selectedRestaurant} />
                  }
                ></Route>
                <Route path="/fnb" element={<FnB />}></Route>
                <Route
                  path="/product/:productId"
                  element={<OneProduct />}
                ></Route>
                <Route
                  path="/products/:restaurantId"
                  element={<Products />}
                ></Route>
                <Route
                  path="/category/:type"
                  element={
                    <CreateRequest
                      setRequestMessageCallback={setRequestMessageCallback}
                      capturedImage={requestImage}
                      requestMessage={requestMessage}
                    />
                  }
                ></Route>
                <Route
                  path="/webcam"
                  element={
                    <WebCamImage
                      setRequestImageCallback={setRequestImageCallback}
                    />
                  }
                ></Route>
              </Routes>
            </div>
          </div>
        </main>
        <BottomNavigator />
      </Router>
    </>
  );
}

export default App;
