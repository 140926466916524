import { useState } from "react";
import FilledButton from "../components/Button";

export default function Cart() {
  const cartItems = localStorage.getItem("cart");

  const importTabs = [
    {
      id: 1,
      name: "No Tips",
      selected: true,
    },
    {
      id: 2,
      name: "10%",
      selected: false,
    },
    {
      id: 3,
      name: "20%",
      selected: false,
    },
    {
      id: 4,
      name: "40%",
      selected: false,
    },
  ];
  const [products, setproducts] = useState(JSON.parse(cartItems) || []);
  const [tabs, setTabs] = useState(importTabs);

  const handleRemove = (id) => {
    setproducts(products.filter((product) => product.id !== id));
    localStorage.setItem(
      "cart",
      JSON.stringify(products.filter((product) => product.id !== id))
    );
  };
  const changeTab = (id) => {
    setTabs(
      tabs.map((tab) => {
        return {
          ...tab,
          selected: id === tab.id,
        };
      })
    );
  };

  return (
    <div className="grid">
      <div className="lg:max-w-lg md:max-w-mg sm:max-w-sm sm:w-5/6 justify-self-center">
        <div className="w-full grid">
          <div className="lg:max-w-lg sm:min-w-sm md:max-w-md sm:w-5/6 justify-self-center">
            {products.map((product) => {
              return (
                <div to={`/product/${product.id}`} className="w-full">
                  <div className="w-full mt-1 border-b-2 border-red-900 p-2">
                    <div className="flex justify-between">
                      <div className="flex">
                        <div className="font-medium p-1 mr-2 border-2 border-gray-300 rounded">
                          {product.count}
                        </div>
                        <div className="font-medium">{product.name}</div>
                      </div>
                      <div className="flex">
                        <div className="mr-2">
                          {"LKR " + product.price * product.count}
                        </div>
                        <div>
                          <FilledButton
                            color="grey"
                            fontColor="black"
                            title="Remove"
                            onClick={() => {
                              handleRemove(product.id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="mt-5 font-medium">Add A Tip</div>
            <div className="flex justify-between mt-2">
              {tabs.map((tab) => {
                return (
                  <div
                    className="mr-2 font-small p-1 rounded border-2 border-black-900"
                    style={{
                      color: tab.selected ? "white" : "black",
                      background: tab.selected ? "black" : "white",
                    }}
                    onClick={() => {
                      changeTab(tab.id);
                    }}
                  >
                    {tab.name}
                  </div>
                );
              })}
            </div>
            <div className="mt-5">
              <div className="flex justify-between">
                <div className="font-small">Subtotal</div>
                <div className="font-small">LKR 100</div>
              </div>
              <div className="flex justify-between">
                <div className="font-small">Room Service Tray changes</div>
                <div className="font-small">LKR 100</div>
              </div>
              <div className="flex justify-between">
                <div className="font-medium">Total</div>
                <div className="font-medium">LKR 300</div>
              </div>
            </div>
            <div className="mt-10">
              <input
                type="text"
                id="first_name"
                className="mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Name (Optional)"
                required
              />
              <select
                id="room"
                className="mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              >
                <option selected>Choose a Room</option>
                <option value="1">124</option>
                <option value="2">233</option>
                <option value="3">3432</option>
                <option value="4">33</option>
              </select>
              <input
                type="text"
                id="order_comment"
                className="mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Order comments"
                required
              />
            </div>
          </div>
        </div>
        {products?.length > 0 && (
          <div className="flex justify-center mt-2">
            <FilledButton
              color="green"
              fontColor="white"
              title="Order"
              onClick={() => {
                console.log("order button pressed");
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
