import ReactStars from "react-rating-stars-component";
import { useRef, useState } from "react";

export default function Feedback() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [tempULR, setTempULR] = useState(null);
  const ref = useRef();

  const handleClick = (e) => {
    ref.current.click();
  };
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const selectFile = (e) => {
    if (e.target?.files?.length > 0) {
      setSelectedFile(e.target.files[0]);
      setTempULR(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Give your feedback
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Enter your feedback and submit.
        </p>
      </div>
      <div className="border-t border-gray-200">
        <div className="px-4 py-5 sm:px-6">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
          >
            Rate your experience
          </label>
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={24}
            activeColor="#ffd700"
          />
          {tempULR && (
            <img
              alt={"kic"}
              src={tempULR}
              className="max-h-40 max-w-100 object-cover rounded m-2"
            />
          )}
          <input
            type="file"
            ref={ref}
            style={{ display: "none" }}
            onChange={(e) => {
              selectFile(e);
            }}
          />
          <label>
            <button
              htmlFor="contained-button-file"
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded"
              onClick={() => {
                handleClick();
              }}
            >
              Upload Image
            </button>
          </label>
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
          >
            Your feedback
          </label>
          <textarea
            id="message"
            rows="4"
            // value={requestMessage || ""}
            // onChange={(e) => {
            //   setRequestMessageCallback(e.target.value);
            // }}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your message..."
          ></textarea>
          <div className="">
            <div className="mt-5 space-x-2">
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
