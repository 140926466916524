import { Link, useParams } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import WebCamImage from "../components/WebCamImage";
import { FaCamera } from "react-icons/fa";

export default function CreateRequest({
  setRequestMessageCallback,
  requestMessage,
}) {
  const params = useParams();
  const { type } = params;
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  const captureImageCallback = (img) => {
    setCapturedImage(img);
  };
  const cameraOnOffCallback = (state) => {
    setIsCameraOn(state);
  };
  const imageCapture = () => {
    if (isCameraOn) {
      return (
        <WebCamImage
          captureImageCallback={captureImageCallback}
          cameraOnOffCallback={cameraOnOffCallback}
        />
      );
    }
    return (
      <div
        className="cursor-pointer	 mt-5 flex items-center justify-center border-dashed border-2 border-gray-400 rounded-2xl hover:bg-gray-100 hover:text-gray-600 text-gray-400"
        onClick={() => setIsCameraOn(true)}
      >
        <FaCamera className="mt-20 mb-20" size="4rem" />
      </div>
    );
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Make Request - {type}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Enter brief description and submit.
        </p>
      </div>
      <div className="border-t border-gray-200">
        <div className="px-4 py-5 sm:px-6">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
          >
            Your message
          </label>
          <textarea
            id="message"
            rows="4"
            value={requestMessage || ""}
            onChange={(e) => {
              setRequestMessageCallback(e.target.value);
            }}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your message..."
          ></textarea>
          {/* {capturedImage !== null && (
            <div className="mt-5">
              <img
                alt="captured image"
                className=" rounded-lg border-2 border-gray-400	"
                src={capturedImage}
              />
            </div>
          )} */}

          <div className="">
            <div className="mt-5">{imageCapture()}</div>
            <div className="mt-5 space-x-2">
              {/* <Link to={"/webcam"}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                {capturedImage !== null ? "Recapture Image" : "Capture Image"}
              </button>
              </Link> */}
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
