import MenuItem from "../components/MenuItem";
import coffee from "../assets/lotti_files/coffee.json";
import cleaning from "../assets/lotti_files/cleaning.json";
import engineering from "../assets/lotti_files/engineering.json";
import foodOrdering from "../assets/lotti_files/food_order.json";
import customerService from "../assets/lotti_files/customer_service.json";
import feedback from "../assets/lotti_files/feedback.json";

export default function Home() {
  const categories = [
    {
      name: "Welcome",
      department: "Welcome",
      id: "000",
      type: "welcome",
      imgUrl: coffee,
      description: "Hotel welcome message",
      page: "/welcome",
    },
    {
      name: "Cleaning",
      department: "House Keeping",
      id: "001",
      type: "cleaning",
      imgUrl: cleaning,
      description: "Inform to room service",
      page: "/category/cleaning",
    },
    {
      name: "Technical Issues",
      department: "Engineering",
      id: "002",
      type: "engineering",
      imgUrl: engineering,
      description: "Inform to engineering service.",
      page: "/category/engineering",
    },
    // {
    //   name: "Food",
    //   department: "Kitchen",
    //   id: "003",
    //   type: "food",
    //   imgUrl: imgKitchen,
    //   description: "This is kitchen service.",
    //   page: "/category/food",
    // },
    {
      name: "F&B ordering",
      department: "Front Office",
      id: "003",
      type: "other",
      imgUrl: foodOrdering,
      description: "Order any food and beverage.",
      page: "/fnb",
    },
    {
      name: "Customer Service",
      department: "customer_service",
      id: "004",
      type: "other",
      imgUrl: customerService,
      description: "Any other service, click here.",
      page: "/category/other",
    },
    {
      name: "Feedback",
      department: "feedback",
      id: "005",
      type: "feedback",
      imgUrl: feedback,
      description: "Give your feedback here.",
      page: "/feedback",
    },
  ];
  return (
    <ul className="gap-x-5 gap-y-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5">
      {categories.map((c) => {
        return <MenuItem key={c.id} id={c.id} item={c} />;
      })}
    </ul>
  );
}
