import CategoryItem from "../components/CategoryItem";
import imgRes1 from "../assets/jpg/res1.jpg";
import imgRes2 from "../assets/jpg/res2.jpg";

export default function Restaurent({ setSelectedRestaurantCallback }) {
  const restaurants = [
    {
      name: "The Verandah",
      id: "000",
      imgUrl: imgRes1,
      description:
        "Lebanese, Moroccan, Middle Eastern, Arabic, Vegetarian Friendly",
      page: "/reservations/0",
    },
    {
      name: "King of the Mambo",
      id: "001",
      imgUrl: imgRes2,
      description:
        "Chinese, Asian, Fusion, Vegetarian Friendly, Moroccan, Thai",
      page: "/reservations/1",
    },
  ];
  return (
    <ul className="gap-x-5 gap-y-5 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {restaurants.map((c) => {
        return (
          <CategoryItem
            key={c.id}
            id={c.id}
            item={c}
            onclick={() => {
              setSelectedRestaurantCallback(c);
            }}
          />
        );
      })}
    </ul>
  );
}
