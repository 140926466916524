import { useState } from "react";
import FilledButton from "../components/Button";
import imgRes1 from "../assets/jpg/res1.jpg";
import imgRes2 from "../assets/jpg/res2.jpg";
import { useParams } from "react-router-dom";

export default function Reservation({ selectedRestaurant }) {
  let { id } = useParams();
  const restaurantImported = [
    {
      name: "The Verandah",
      id: "000",
      image: imgRes1,
      description:
        "Lebanese, Moroccan, Middle Eastern, Arabic, Vegetarian Friendly",
      page: "/products/1",
    },
    {
      name: "King of the Mambo",
      id: "001",
      image: imgRes2,
      description:
        "Lebanese, Moroccan, Middle Eastern, Arabic, Vegetarian Friendly",
      page: "/products/1",
    },
  ];
  const [restaurant, setRestaurant] = useState(restaurantImported);

  return (
    <div className="grid">
      <div className="lg:max-w-lg md:max-w-mg sm:max-w-sm sm:w-5/6 justify-self-center">
        <img
          alt={"kic"}
          src={restaurant[id].image}
          className="max-h-40 w-full object-cover rounded"
        />
        <div className="text-3xl font-medium mt-2 mb-5">
          {restaurant[id].name}
        </div>
        <div className="flex justify-between">
          <div className="font-medium min-w-[40%]">Date</div>
          <input
            type="text"
            id="first_name"
            className="mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="Enter date here"
            required
          />
        </div>
        <div className="flex justify-between">
          <div className="font-medium min-w-[40%]">Time</div>
          <input
            type="text"
            id="first_name"
            className="mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="Enter time here"
            required
          />
        </div>
        <div className="flex justify-between">
          <div className="font-medium min-w-[40%]">Number of seats</div>
          <input
            type="text"
            id="first_name"
            className="mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="How many seats"
            required
          />
        </div>
        <div className="flex justify-between">
          <div className="font-medium min-w-[40%]">Additional Information</div>
          <input
            type="text"
            id="first_name"
            className="mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="Additional info (Optional)"
            required
          />
        </div>
        <div className="flex justify-center mt-4">
          <FilledButton
            title="Make a reservation"
            color="green"
            fontColor="white"
          />
        </div>
      </div>
    </div>
  );
}
