import imgWelcome1 from "../assets/jpg/welcome1.jpg";
import FilledButton from "../components/Button";
import { useState } from "react";

export default function Bill() {
  const productListImported = [
    {
      id: 1,
      name: "Goat's curd flat breads",

      price: 30,
      count: 2,
    },
    {
      id: 2,
      name: "Padron Peppers",

      price: 28,
      count: 10,
    },
    {
      id: 3,
      name: "Golden Triple Rooms",

      price: 150,
      count: 1,
    },
    {
      id: 4,
      name: "Golden Double Rooms",

      price: 100,
      count: 2,
    },
  ];
  const importTabs = [
    {
      id: 1,
      name: "No Tips",
      selected: true,
    },
    {
      id: 2,
      name: "10%",
      selected: false,
    },
    {
      id: 3,
      name: "20%",
      selected: false,
    },
    {
      id: 4,
      name: "40%",
      selected: false,
    },
  ];
  const [products, setproducts] = useState(productListImported);
  const [tabs, setTabs] = useState(importTabs);

  const handleRemove = (id) => {
    setproducts(products.filter((product) => product.id !== id));
  };
  const changeTab = (id) => {
    setTabs(
      tabs.map((tab) => {
        return {
          ...tab,
          selected: id === tab.id,
        };
      })
    );
  };

  return (
    <div className="grid">
      <h1 className="text-center text-xl font-medium mb-5">
        Your Total Bill Upto Now
      </h1>
      <div className="lg:max-w-lg md:max-w-mg sm:max-w-sm sm:w-5/6 justify-self-center">
        <div className="w-full grid">
          <div className="lg:max-w-lg sm:min-w-sm md:max-w-md sm:w-5/6 justify-self-center">
            {products.map((product) => {
              return (
                <div to={`/product/${product.id}`} className="w-full">
                  <div className="w-full mt-1 border-b-2 border-black-900 p-2">
                    <div className="flex justify-between">
                      <div className="flex">
                        <div className="font-medium p-1 mr-2 mt-1">
                          {product.count} x
                        </div>
                        <div className="mt-2 font-medium">{product.name}</div>
                      </div>
                      <div className="flex">
                        <div className="mt-2">
                          {"$ " + product.price * product.count}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="mt-5">
              <div className="flex justify-between">
                <div className="font-small">Subtotal</div>
                <div className="font-small">$ 340</div>
              </div>
              <div className="flex justify-between">
                <div className="font-small">Tax (10%)</div>
                <div className="font-small">$ 100</div>
              </div>
              <div className="flex justify-between">
                <div className="font-small">Service changes</div>
                <div className="font-small">$ 20</div>
              </div>
              <div className="flex justify-between border-double border-b-4 border-black-900  pb-1">
                <div className="font-medium">Total</div>
                <div className="font-medium">$ 810</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
