import { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";

export default function WebCamImage({
  captureImageCallback,
  cameraOnOffCallback,
}) {
  const [image, setImage] = useState("");
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    captureImageCallback(imageSrc);
  });

  const videoConstraints = {
    width: 600,
    // height: 200,
    facingMode: "user",
  };

  return (
    <div className="">
      <div className="webcam-container rounded-lg border border-gray-300 p-2">
        <div className="webcam-img flex justify-center">
          {image === "" ? (
            <Webcam
              className="rounded-lg"
              videoConstraints={videoConstraints}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
          ) : (
            <img src={image} alt="captured" />
          )}
        </div>
        <div className="mt-2 ">
          {image !== "" ? (
            <div className="flex space-x-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setImage("");
                }}
                className="w-full webcam-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Retake Image
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setImage("");
                  captureImageCallback(null);
                  cameraOnOffCallback(false);
                }}
                className="w-full webcam-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Remove
              </button>
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
              className="w-full webcam-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Capture
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
