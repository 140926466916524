export default function Requests() {
  const requests = [
    {
      _id: 2,
      title: "Clean the room 234",
      type: "CLEANING",
      description: "Need to clean whole the room before today 3.00pm.",
      status: {
        _id: 1,
        name: "In Progress",
      },
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
    {
      _id: 3,
      title: "Order 342 - Pot Au Feu",
      type: "CLEANING",
      description: "Need to clean whole the room before today 3.00pm.",
      status: {
        _id: 3,
        name: "Assigned",
        color: "Orange",
      },
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
    {
      _id: 4,
      title: "TV is not working",
      type: "Engineering",
      description: "No power for television",
      status: {
        _id: 2,
        name: "Done",
        color: "green",
      },
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
    {
      _id: 5,
      title: "Water leak in washroom",
      type: "CLEANING",
      description: "Fix the issue ASAP",
      status: {
        _id: 4,
        name: "Pending",
      },
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
    {
      _id: 6,
      title: "Order 319 - The Aviary",
      type: "Food",
      description:
        "Grilled hispi cabbage, Vitruvian Negroni, Fibonacci Martini",
      status: {
        _id: 2,
        name: "Done",
        color: "green",
      },
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
    {
      _id: 7,
      title: "Order 456 - Pot Au Feu",
      type: "FOOD",
      description: "Goat's curd flat breads, Padron Peppers",
      status: {
        _id: 2,
        name: "Done",
        color: "green",
      },
      department: "House Keeping",
      assignee: {
        _id: 12,
        position: "ENGINEER",
        name: "Mr. Kamal Perera",
        contact: "07790934675",
      },
    },
  ];

  const COLOR_MAP = {
    1: "red",
    2: "green",
    3: "orange",
    4: "yellow",
    5: "yellow",
  };

  let color = "green";

  return (
    <div>
      <div className="container -mt-5 mx-auto">
        <div className="text-2xl mb-5 font-medium text-center">My Requests</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {requests.map((e) => {
            return (
              <div
                key={e._id}
                className="card m-2 cursor-pointer border border-gray-400 rounded-lg hover:shadow-md hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200"
              >
                <div className="m-3">
                  <h2 className="text-lg mb-2">
                    {e.title}
                    <span
                      //   className={`text-sm text-black-800 bg-${
                      //     // COLOR_MAP[e.status._id]
                      //     color
                      //   }-200 inline rounded-full px-2 align-top float-right animate-pulse`}
                      className={`text-sm text-black-800 bg-green-200 inline rounded-full px-2 align-top float-right animate-pulse`}
                    >
                      {e.status.name}
                    </span>
                  </h2>
                  <p className="text-sm text-gray-700 hover:text-gray-900 transition-all duration-200">
                    {e.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
