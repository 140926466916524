import CategoryItem from "../components/CategoryItem";
import imgRes1 from "../assets/jpg/ird.jpg";
import imgRes2 from "../assets/jpg/res2.jpg";

export default function FnB() {
  const restaurants = [
    {
      name: "In Room Dining",
      id: "000",
      imgUrl: imgRes1,
      description:
        "Lebanese, Moroccan, Middle Eastern, Arabic, Vegetarian Friendly",
      page: "/products/1",
    },
    {
      name: "Restaurents",
      id: "001",
      imgUrl: imgRes2,
      description:
        "Chinese, Asian, Fusion, Vegetarian Friendly, Moroccan, Thai",
      page: "/restaurents",
    },
  ];
  return (
    <ul className="gap-x-5 gap-y-5 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {restaurants.map((c) => {
        return <CategoryItem key={c.id} id={c.id} item={c} />;
      })}
    </ul>
  );
}
