import { Link } from "react-router-dom";

export default function CategoryItem({ id, item }) {
  return (
    <li className="">
      <Link to={item.page}>
        <div className="flex flex-wrap place-items-center h-full">
          <div className="overflow-hidden shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-5 hover:shadow-2xl rounded-lg w-80 md:w-80 cursor-pointer m-auto mb-3">
            <button className="w-full block h-full">
              <img
                alt={item.name}
                src={item.imgUrl}
                className="max-h-40 w-full object-cover"
              />
              <div className="bg-white w-full p-4 text-center">
                <p className="text-indigo-500 text-2xl font-medium ">
                  {item.name}
                </p>
                <p className="text-gray-800 text-sm font-medium mb-2">
                  {item.description}
                </p>
              </div>
            </button>
          </div>
        </div>
      </Link>
    </li>
  );
}
