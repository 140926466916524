import imgWelcome1 from "../assets/jpg/welcome1.jpg";
import FilledButton from "../components/Button";

export default function Welcome() {
  return (
    <div className="grid">
      <div className="lg:max-w-lg md:max-w-mg sm:max-w-sm justify-self-center">
        <div className="text-3xl font-medium text-center">
          Welcome to Xempler Hotel Portal
        </div>
        <div className="text-xl font-medium text-center mt-3">
          The future of Guest Services
        </div>
        <img
          alt="Welcome Image"
          src={imgWelcome1}
          className="max-h-40 w-full object-cover mt-5 rounded-md"
        />
        <div className="text-lg mt-3">
          With powerful and easy to use tools, Xempler hotel portal requires no
          expensive development costs for you... and no app download for your
          guests. There are templates you can choose to get you started with
          placeholder buttons and examples. This popup could contain a welcome
          and general information about your hotel, but every hotel is
          different, the design is your choice. Upload your logo, use your
          colors and populate with your content. Your Xempler hotel portal
          design can include many features such as:
        </div>
        <ul className="text-xl font-medium text-center mt-2">
          <div className="border border-black-600 mb-2 p-1 rounded">
            Guest Directory
          </div>
          <div className="border border-black-600 mb-2 p-1 rounded">
            Speed Dials
          </div>
          <div className="border border-black-600 mb-2 p-1 rounded">
            Guest Chat
          </div>
          <div className="border border-black-600 mb-2 p-1 rounded">
            Room Service
          </div>
          <div className="border border-black-600 mb-2 p-1 rounded">
            F&B Ordering
          </div>
          <div className="border border-black-600 mb-2 p-1 rounded">
            View Bill
          </div>
        </ul>
        <div className="text-lg mt-3">
          If you would like to speak with us about setting up your own Xempler
          hotel portal for your guests, or wish to know how it can benefit your
          hotel further, then please contact us below.
        </div>
        <div className="flex justify-center mt-3">
          <div className="mr-4">
            <FilledButton
              color="green"
              fontColor="white"
              title="Call Us"
              onClick={() => {
                console.log("button pressed");
              }}
            />
          </div>

          <FilledButton
            color="green"
            fontColor="white"
            title="Email Us"
            onClick={() => {
              console.log("button pressed");
            }}
          />
        </div>
      </div>
    </div>
  );
}
